export const ScriptLoader = (src) => {
    return new Promise((resolve, reject) => {
        if (typeof document !== 'undefined') {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.onload = resolve;
            script.src = src;
            document.getElementsByTagName('head')[0].appendChild(script);
        }
        else {
            reject();
        }
    });
};

export const StylesLoader = (src) => {
    return new Promise((resolve, reject) => {
        if (typeof document !== 'undefined') {
            const style = document.createElement('link');
            style.rel = 'stylesheet';
            style.type = 'text/css';
            style.onload = resolve;
            style.href = src;
            document.getElementsByTagName('head')[0].appendChild(style);
        }
        else {
            reject();
        }
    });
};
