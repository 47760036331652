import actionStates from './actionStates';
import API from '../../helpers/API';

export const publicPanels = {
    SNIPPETS: 'SNIPPETS',
    LISTS: 'LISTS',
    COLLECTIONS: 'COLLECTIONS',
};

const initialState = {
    panel: publicPanels.SNIPPETS,
    status: actionStates.NOT_LOADED,
    offset: 0,
    limit: 20,
    collection: null,
    list: null,
    snippet: null,
    items: [],
};

const actionTypes = {
    PUBLIC_SWITCH_PANEL: 'PUBLIC_SWITCH_PANEL',
    PUBLIC_LOADING: 'PUBLIC_LOADING',
    PUBLIC_LOADED: 'PUBLIC_LOADED',
    PUBLIC_ERROR: 'PUBLIC_ERROR',
    PUBLIC_NEXT_PAGE: 'PUBLIC_NEXT_PAGE',
    PUBLIC_PREV_PAGE: 'PUBLIC_PREV_PAGE',
    PUBLIC_SELECT: 'PUBLIC_SELECT',
    PUBLIC_SET_SNIPPET: 'PUBLIC_SET_SNIPPET',
    PUBLIC_SET_LIST: 'PUBLIC_SET_LIST',
    PUBLIC_SET_COLLECTION: 'PUBLIC_SET_COLLECTION',
};

export const publicReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PUBLIC_SWITCH_PANEL:
            return {
                ...state,
                panel: publicPanels[action.panel] ? action.panel : null,
                items: [],
            };
        case actionTypes.PUBLIC_LOADING:
            return {
                ...state,
                status: actionStates.LOADING,
                offset: action.newOffset,
                items: [],
            };
        case actionTypes.PUBLIC_LOADED:
            return {
                ...state,
                status: actionStates.LOADED,
                items: [ ...action.items ]
            };
        case actionTypes.PUBLIC_SET_COLLECTION:
            return {
                ...state,
                collection: action.item,
            };
        case actionTypes.PUBLIC_SET_LIST:
            return {
                ...state,
                list: action.item,
            };
        case actionTypes.PUBLIC_SET_SNIPPET:
            return {
                ...state,
                snippet: action.item,
            };
        default:
            return state;
    }
};

export const switchPanel = (panel) => ({ type: actionTypes.PUBLIC_SWITCH_PANEL, panel });

export const fetchSnippets = (parent = null, page = 1, callback = () => {}) => {
    return fetchItems(publicPanels.SNIPPETS, parent, page, callback);
};

export const fetchLists = (parent = null, page = 1, callback = () => {}) => {
    return fetchItems(publicPanels.LISTS, parent, page, callback);
};

export const fetchCollections = (parent = null, page = 1, callback = () => {}) => {
    return fetchItems(publicPanels.COLLECTIONS, null, page, callback);
};

export const fetchItems = (what, parent = null, page = 1, callback = () => {}) => (dispatch, getState) => {
    const { explore } = getState();
    const newOffset = explore.limit * (page - 1);

    const path = `public/${what.toLowerCase()}${parent ? `/${parent}` : ''}`;
    const query = `limit=${explore.limit}${newOffset ? `&offset=${newOffset}` : ''}`;

    API(dispatch).call({
        url: `${path}?${query}`,
        beforeLoad: () => dispatch({ type: actionTypes.PUBLIC_LOADING, offset: newOffset }),
        onSuccess: (response) => {
            dispatch({
                type: actionTypes.PUBLIC_LOADED,
                items: response.snippets || response.categories || [],
            });
            callback();
        },
        onFail: error => dispatch({ type: actionTypes.PUBLIC_ERROR }),
    });
};

export const setOne = (what, item) => {
    switch(what) {
        case publicPanels.COLLECTIONS:
            return { type: actionTypes.PUBLIC_SET_COLLECTION, item };
        case publicPanels.LISTS:
            return { type: actionTypes.PUBLIC_SET_LIST, item };
        case publicPanels.SNIPPETS:
        default:
            return { type: actionTypes.PUBLIC_SET_SNIPPET, item };
    }
};


export const fetchOne = (what, id) => (dispatch) => {
    API(dispatch).call({
        url: `public/${what.toLowerCase()}/one/${id}`,
        beforeLoad: () => {},
        onSuccess: (response) => {
            dispatch(setOne(what, response.snippet || response.category || null));
        },
        onFail: error => dispatch({ type: actionTypes.PUBLIC_ERROR }),
    });
};

export const bookmarkCategory = (id) => (dispatch) => {
    API(dispatch).call({
        url: `public/categories/bookmark/${id}`,
        method: 'POST',
        data: {},
        onSuccess: (response) => {
            const { category } = response;
            const what = category.is_collection ? publicPanels.COLLECTIONS : publicPanels.LISTS;

            dispatch(setOne(what, category));
        },
        onFail: error => dispatch({ type: actionTypes.CATEGORIES_ERROR }),
    });
};
