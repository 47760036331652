import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import ReduxThunk from 'redux-thunk'

import { collectionsReducer } from './actions/collections';
import { categoriesReducer } from './actions/categories';
import { snippetsReducer } from './actions/snippets';
import { searchReducer } from './actions/search';
import { publicReducer } from './actions/public';
import { embedReducer } from './actions/embed';
import { userReducer } from './actions/user';
import { sharingReducer } from './actions/sharing';
import { appReducer } from './actions/app';
import { cmReducer } from './actions/cm';
import { extensionsReducer } from './actions/extensions';
import { paymentReducer } from './actions/payment';
import { dialogReducer } from '../ui/components/dialog/dialogStore';
import { dropdownReducer } from '../ui/components/dropdown/dropdownStore';
import { popoverReducer } from '../ui/components/popover/popoverStore';


const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// TODO: add middlewares here
const middlewares = [ReduxThunk];

const createStoreWithMiddleware = composeEnhancers(applyMiddleware(
    ...middlewares
))(createStore);


const rootReducer = combineReducers({
    collections: collectionsReducer,
    categories: categoriesReducer,
    snippets: snippetsReducer,
    search: searchReducer,
    explore: publicReducer,
    embed: embedReducer,
    user: userReducer,
    sharing: sharingReducer,
    app: appReducer,
    cm: cmReducer,
    payment: paymentReducer,
    dialog: dialogReducer,
    dropdown: dropdownReducer,
    popover: popoverReducer,
    extensions: extensionsReducer,
});


export default function configureStore(initialState) {
    return createStoreWithMiddleware(rootReducer, initialState);
}
