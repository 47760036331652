import actionStates from './actionStates';
import API from '../../helpers/API';

import {
    actionTypes as categoriesActionTypes,
    CategoryModel,
    categoryIncrement,
    categoryDecrement,
} from './categories';

export const SnippetModel = (snippet, category = null) => ({
    id: (snippet && snippet.id) || -1,
    title: (snippet && snippet.title) || '',
    alias: (snippet && snippet.alias) || '',
    url: (snippet && snippet.url) || null,
    is_favorite: (snippet && snippet.is_favorite) || false,
    code: (snippet && snippet.code) || '',
    category: (snippet && snippet.Category) || category || CategoryModel(),
    language: (snippet && snippet.Language && snippet.Language.alias) || 'text',
    tags: (snippet && snippet.Tags && snippet.Tags.map(tag => tag.name)) || [],
    date_added: (snippet && snippet.date_added) || (new Date()).getUTCDate(),
    visibility: (snippet && snippet.visibility) || 'public',
});

export const snippetPanelStates = {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    LOADING: 'LOADING',
};

const initialState = {
    status: actionStates.NOT_LOADED,
    selected: null,
    view: snippetPanelStates.VIEW,
    list: [],
};

const actionTypes = {
    SNIPPETS_LOADING: 'SNIPPETS_LOADING',
    SNIPPETS_LOADED: 'SNIPPETS_LOADED',
    SNIPPETS_ADD: 'SNIPPETS_ADD',
    SNIPPETS_UPDATE: 'SNIPPETS_UPDATE',
    SNIPPETS_REMOVE: 'SNIPPETS_REMOVE',
    SNIPPETS_ERROR: 'SNIPPETS_ERROR',
    SNIPPET_SELECT: 'SNIPPET_SELECT',
    SNIPPET_EDIT: 'SNIPPET_EDIT',
    SNIPPET_SAVING: 'SNIPPET_SAVING',
    SNIPPET_SAVED: 'SNIPPET_SAVED',
    SNIPPET_STAR: 'SNIPPET_STAR',
};


export const snippetsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SNIPPETS_LOADING:
            return {
                ...state,
                status: actionStates.LOADING,
            };
        case actionTypes.SNIPPETS_ERROR:
            return {
                ...state,
                status: actionStates.ERROR,
            };
        case actionTypes.SNIPPETS_LOADED:
            return {
                ...state,
                status: actionStates.LOADED,
                list: [ ...action.snippets.sort((a, b) => b.date_added.localeCompare(a.date_added)) ],
            };
        case actionTypes.SNIPPETS_ADD:
            return {
                ...state,
                status: actionStates.LOADED,
                list: action.before
                    ? [ action.snippet, ...state.list ]
                    : [ ...state.list, action.snippet ],
            };
        case actionTypes.SNIPPETS_UPDATE:
            return {
                ...state,
                list: state.list.map(s => {
                    if (s.id === action.snippet.id) {
                        return { ...action.snippet };
                    }
                    return s;
                }),
            };
        case actionTypes.SNIPPETS_REMOVE:
            return {
                ...state,
                status: actionStates.LOADED,
                list: state.list.filter(s => s.id !== action.id),
            };

        case actionTypes.SNIPPET_SELECT:
            let newState = {
                ...state,
                selected: action.id ? parseInt(action.id, 10) : null,
            };

            if (action.id > 0) {
                newState = {
                    ...newState,
                    view: snippetPanelStates.VIEW,
                    list: state.list.filter(s => s.id > 0),
                };
            }

            return newState;

        case actionTypes.SNIPPET_EDIT:
            return {
                ...state,
                view: snippetPanelStates.EDIT,
            };
        case actionTypes.SNIPPET_SAVING:
            return {
                ...state,
                view: snippetPanelStates.LOADING,
            };
        case actionTypes.SNIPPET_SAVED:
            return {
                ...state,
                view: snippetPanelStates.VIEW,
            };
        case actionTypes.SNIPPET_STAR:
            return {
                ...state,
                list: state.list.map(s => ({
                    ...s,
                    ...(s.id === action.id && { is_favorite: !s.is_favorite }),
                })),
            };
        case categoriesActionTypes.CATEGORY_UPDATE:
            return {
                ...state,
                list: state.list.map(s => ({
                    ...s,
                    category: {
                        ...s.category,
                        ...(s.category.id === action.category.id && {
                            name: action.category.name,
                            alias: action.category.alias,
                            visibility: action.category.visibility,
                        }),
                    },
                })),
            };
        default:
            return state;
    }
};


export const fetchSnippets = categoryId => dispatch => {
    API(dispatch).call({
        url: `snippets/category/${categoryId}`,
        beforeLoad: () => dispatch({ type: actionTypes.SNIPPETS_LOADING }),
        onSuccess: (response) => {
            if (response.status === 'ok') {
                dispatch({
                    type: actionTypes.SNIPPETS_LOADED,
                    snippets: response.snippets.map(s => SnippetModel(s)),
                });
            } else {
                dispatch({ type: actionTypes.SNIPPETS_ERROR });
            }
        },
        onFail: error => dispatch({ type: actionTypes.SNIPPETS_ERROR }),
    });
};

export const addSnippet = (snippet, before = false) => ({ type: actionTypes.SNIPPETS_ADD, snippet, before });
export const updateSnippet = snippet => ({ type: actionTypes.SNIPPETS_UPDATE, snippet });
export const removeSnippet = id => ({ type: actionTypes.SNIPPETS_REMOVE, id });
export const selectSnippet = id => ({ type: actionTypes.SNIPPET_SELECT, id });
export const beginEdit = () => ({ type: actionTypes.SNIPPET_EDIT });
export const cancelEdit = () => dispatch => {
    return dispatch({ type: actionTypes.SNIPPET_SAVED });
};

export const newSnippet = afterCreate => (dispatch, getState) => {
    const { snippets, categories } = getState();

    const existingSnippets = snippets.list;
    const category = categories.list.find(c => c.id === categories.selected);

    if (existingSnippets.filter(s => s.id === -1).length) {
        return;
    }

    const emptySnippet = SnippetModel(null, category);

    dispatch(beginEdit());
    dispatch(addSnippet(emptySnippet, true));

    if (afterCreate) {
        afterCreate();
    }
};

export const saveEdit = (snippet, isOwner = true, afterSave = null) => (dispatch, getState) => {
    const { categories } = getState();
    const categoryId = categories.selected;
    const snippetId = snippet.id;
    const isNew = (snippet.id <= 0);

    const data = {
        snippet_id: (snippet.id > 0 ? snippet.id : null),
        category_id: snippet.category.id,
        title: snippet.title,
        url: snippet.url,
        language: snippet.language,
        tags: snippet.tags,
        code: snippet.code,
        visibility: 'public',
    };

    API(dispatch).call({
        url: 'snippets/save',
        method: 'POST',
        data: { ...data },
        beforeLoad: () => dispatch({ type:actionTypes.SNIPPET_SAVING }),
        onSuccess: (response) => {
            if (response.status === 'ok') {
                const responseCategoryId = parseInt(response.bookmarked_category || response.snippet.category_id, 10) || 0;

                if (isNew || (categoryId !== responseCategoryId)) {
                    dispatch(categoryIncrement(responseCategoryId));
                }

                if (!isNew && (categoryId !== responseCategoryId) && isOwner) {
                    dispatch(categoryDecrement(categoryId))
                }

                if (isNew) {
                    snippet.id = response.snippet.id;
                    dispatch(removeSnippet(snippetId));
                    dispatch(addSnippet(snippet, true));
                } else {
                    dispatch(updateSnippet(snippet));
                }

                if (typeof afterSave === 'function') {
                    afterSave(responseCategoryId, response.snippet.id);
                }

                dispatch({ type: actionTypes.SNIPPET_SAVED });
            } else {
                dispatch({ type: actionTypes.SNIPPETS_ERROR });
                console.error(response);
            }
        },
        onFail: error => dispatch({ type: actionTypes.SNIPPETS_ERROR }),
    });
};

export const toggleStar = id => dispatch => {
    API(dispatch).call({
        url: 'snippets/fav',
        method: 'POST',
        data: { snippet_id: id },
        beforeLoad: () => dispatch({ type: actionTypes.SNIPPET_STAR, id }),
        onSuccess: () => {},
        onFail: error => dispatch({ type: actionTypes.SNIPPETS_ERROR }),
    });
};

export const deleteSnippet = (snippet, onRemove = null) => (dispatch, getState) => {
    const { categories } = getState();

    API(dispatch).call({
        url: 'snippets/delete',
        method: 'POST',
        data: { snippet_id: snippet.id },
        beforeLoad: () => {},
        onSuccess: () => {
            dispatch(removeSnippet(snippet.id));
            dispatch(categoryDecrement(categories.selected));

            if (onRemove) {
                onRemove();
            }
        },
        onFail: error => dispatch({ type: actionTypes.SNIPPETS_ERROR }),
    });
};
