export const modes = {
    // c-like
    clike: {
        primary: true,
    },
    c: {
        displayName: 'C',
        primary: false,
        alias: 'clike',
        mimes: [ { mime: 'text/x-csrc' } ],
    },
    cpp: {
        displayName: 'C++',
        primary: false,
        alias: 'clike',
        mimes: [ { mime: 'text/x-c++src' } ],
        options: {
            useCPP: true,
        },
    },
    cs: {
        displayName: 'C#',
        primary: false,
        alias: 'clike',
        mimes: [ { mime: 'text/x-csharp' } ],
    },
    java: {
        displayName: 'Java',
        primary: false,
        alias: 'clike',
        mimes: [ { mime: 'text/x-java' } ],
    },
    objectivec: {
        displayName: 'Objective-C',
        primary: false,
        alias: 'clike',
        mimes: [ { mime: 'text/x-objectivec' } ],
    },
    swift: {
        displayName: 'Swift',
        primary: true,
        mimes: [ { mime: 'text/x-swift' } ],
    },
    scala: {
        displayName: 'Scala',
        primary: false,
        alias: 'clike',
        mimes: [ { mime: 'text/x-scala' } ],
    },
    ceylon: {
        displayName: 'Ceylon',
        primary: false,
        alias: 'clike',
        mimes: [ { mime: 'text/x-ceylon' } ],
    },
    squirrel: {
        displayName: 'Squirrel',
        primary: false,
        alias: 'clike',
        mimes: [ { mime: 'text/x-squirrel' } ],
    },
    kotlin: {
        displayName: 'Kotlin',
        primary: false,
        alias: 'java',
    },
    // mllike
    mllike: {
        primary: true,
    },
    fsharp: {
        displayName: 'F#',
        primary: false,
        alias: 'mllike',
        mimes: [ { mime: 'text/x-fsharp' } ],
    },
    ocaml: {
        displayName: 'OCaml',
        primary: false,
        alias: 'mllike',
        mimes: [ { mime: 'text/x-ocaml' } ],
    },
    // css
    css: {
        displayName: 'CSS',
        primary: true,
        mimes: [ { mime: 'text/css' } ],
    },
    sass: {
        displayName: 'SASS',
        primary: true,
        dependencies: ['css'],
        mimes: [ { mime: 'text/x-sass' } ],
    },
    scss: {
        displayName: 'SCSS',
        primary: false,
        alias: 'sass',
        mimes: [ { mime: 'text/x-scss' } ],
    },
    less: {
        displayName: 'LESS',
        primary: false,
        alias: 'sass',
        mimes: [ { mime: 'text/x-less' } ],
    },
    stylus: {
        displayName: 'Stylus',
        primary: true,
        mimes: [ { mime: 'text/x-styl' } ],
    },
    // markup
    xml: {
        displayName: 'XML',
        primary: true,
        mimes: [ { mime: 'application/xml' } ],
    },
    html: {
        primary: false,
        alias: 'xml',
        mimes: [ { mime: 'text/html' } ],
        options: {
            htmlMode: true,
            matchClosing: true,
            alignCDATA: false,
        },
    },
    htmlmixed: {
        displayName: 'HTML',
        primary: true,
        dependencies: ['xml', 'javascript', 'css'],
        mimes: [ { mime: 'text/html' } ],
    },
    django: {
        displayName: 'Django',
        primary: true,
        dependencies: ['htmlmixed'],
        mimes: [ { mime: 'text/x-django' } ],
    },
    pug: {
        displayName: 'Pug',
        primary: true,
        dependencies: ['htmlmixed'],
        mimes: [ { mime: 'text/x-pug' } ],
        options: {
            alignCDATA: true,
        },
    },
    jade: {
        displayName: 'Jade',
        primary: false,
        alias: 'pug',
        mimes: [ { mime: 'text/x-jade' } ],
    },
    handlebars: {
        displayName: 'Handlebars',
        primary: true,
        mimes: [ { mime: 'text/x-handlebars-template' } ],
        options: {
            base: 'text/html',
        },
    },
    haml: {
        displayName: 'HAML',
        primary: true,
        mimes: [ { mime: 'text/x-haml' } ],
    },
    vue: {
        displayName: 'Vue.js',
        primary: true,
        dependencies: ['coffeescript', 'sass', 'pug', 'handlebars'],
        mimes: [ { mime: 'text/x-vue' } ],
    },
    smarty: {
        displayName: 'Smarty',
        primary: true,
        dependencies: ['xml'],
        options: {
            version: 3,
            baseMode: 'text/html',
        },
    },
    soy: {
        displayName: 'Soy',
        primary: true,
        dependencies: ['htmlmixed'],
        mimes: [ { mime: 'text/x-soy' } ],
    },
    tornado: {
        displayName: 'Tornado',
        primary: true,
        dependencies: ['htmlmixed'],
        mimes: [ { mime: 'text/x-tornado' } ],
    },
    twig: {
        displayName: 'Twig',
        primary: true,
        options: {
            htmlMode: true,
        },
    },
    velocity: {
        displayName: 'Velocity',
        primary: true,
        mimes: [ { mime: 'text/velocity' } ],
    },
    markdown: {
        primary: true,
        mimes: [ { mime: 'text/x-markdown' } ],
    },
    text: {
        displayName: 'Text',
        primary: false,
        alias: 'markdown',
        mimes: [ { mime: 'text/plain' } ],
    },
    gfm: {
        primary: true,
        dependencies: ['markdown'],
        mimes: [ { mime: 'text/x-gfm' } ],
    },
    md: {
        displayName: 'Markdown',
        primary: false,
        alias: 'gfm',
    },
    // js
    javascript: {
        displayName: 'JavaScript',
        primary: true,
        mimes: [ { mime: 'text/javascript' } ],
    },
    coffeescript: {
        displayName: 'CoffeeScript',
        primary: true,
        mimes: [ { mime: 'text/x-coffeescript' } ],
    },
    typescript: {
        displayName: 'TypeScript',
        primary: false,
        alias: 'javascript',
        mimes: [ { mime: 'text/typescript' } ],
        options: {
            typescript: true,
        },
    },
    jsx: {
        displayName: 'JSX',
        primary: true,
        dependencies: ['javascript', 'xml'],
        mimes: [ { mime: 'text/jsx' } ],
    },
    tsx: {
        displayName: 'JSX (TypeScript)',
        primary: false,
        alias: 'jsx',
        mimes: [ { mime: 'text/typescript-jsx' } ],
    },
    json: {
        displayName: 'JSON',
        primary: false,
        alias: 'javascript',
        mimes: [ { mime: 'application/json' } ],
        options: {
            json: true,
        },
    },
    jsonld: {
        displayName: 'JSON-LD',
        primary: false,
        alias: 'javascript',
        mimes: [ { mime: 'application/ld+json' } ],
        options: {
            jsonld: true,
        },
    },
    http: {
        displayName: 'HTTP',
        primary: true,
        mimes: [ { mime: 'message/http' } ],
    },
    haxe: {
        displayName: 'Haxe',
        primary: true,
        mimes: [ { mime: 'text/x-haxe' } ],
    },
    hxml: {
        displayName: 'Hxml',
        primary: false,
        alias: 'haxe',
        mimes: [ { mime: 'text/x-hxml' } ],
    },
    // config
    yaml: {
        displayName: 'YAML',
        primary: true,
    },
    dockerfile: {
        displayName: 'Dockerfile',
        primary: true,
    },
    // ...
    php: {
        displayName: 'PHP',
        primary: true,
        dependencies: ['clike', 'htmlmixed'],
        mimes: [
            { mime: 'application/x-httpd-php', match: '<?' },
            { mime: 'text/x-php' },
        ],
    },
    laravelblade: {
        displayName: 'Laravel Blade',
        primary: true,
        dependencies: ['php'],
    },
    python: {
        displayName: 'Python',
        primary: true,
        mimes: [
            { mime: 'text/x-cython', match: 'cython' },
            { mime: 'text/x-python' },
        ],
    },
    ruby: {
        displayName: 'Ruby',
        primary: true,
        mimes: [ { mime: 'text/x-ruby' } ],
    },
    go: {
        displayName: 'Go',
        primary: true,
        mimes: [ { mime: 'text/x-go' } ],
    },
    /*brainfuck: {
        displayName: 'Brainfuck',
        primary: true,
        mimes: [ { mime: 'text/x-brainfuck' } ],
    },*/
    cmake: {
        displayName: 'CMake',
        primary: true,
        mimes: [ { mime: 'text/x-cmake' } ],
    },
    commonlisp: {
        displayName: 'Common Lisp',
        primary: true,
        mimes: [ { mime: 'text/x-common-lisp' } ],
    },
    crystal: {
        displayName: 'Crystal',
        primary: true,
        mimes: [ { mime: 'text/x-crystal' } ],
    },
    mathematica: {
        displayName: 'Mathematica',
        primary: true,
        mimes: [ { mime: 'text/x-mathematica' } ],
    },
    vb: {
        displayName: 'VB.NET',
        primary: true,
        mimes: [ { mime: 'text/x-vb' } ],
    },
    vbscript: {
        displayName: 'VBScript',
        primary: true,
    },
    // sql
    sql: {
        displayName: 'SQL',
        primary: true,
        mimes: [
            { mime: 'text/x-sql' },
            /*text/x-mariadb,
            text/x-cassandra,
            text/x-hive,
            text/x-gql*/
        ],
    },
    mysql: {
        displayName: 'MySQL',
        primary: false,
        alias: 'sql',
        mimes: [ { mime: 'text/x-mysql' } ],
    },
    mssql: {
        displayName: 'MS SQL',
        primary: false,
        alias: 'sql',
        mimes: [ { mime: 'text/x-mssql' } ],
    },
    plsql: {
        displayName: 'PL/SQL',
        primary: false,
        alias: 'sql',
        mimes: [ { mime: 'text/x-plsql' } ],
    },
    pgsql: {
        displayName: 'PostgreSQL',
        primary: false,
        alias: 'sql',
        mimes: [ { mime: 'text/x-pgsql' } ],
    },
    cypher: {
        displayName: 'Cypher',
        primary: true,
        mimes: [ { mime: 'application/x-cypher-query' } ],
    },
    solr: {
        displayName: 'Solr',
        primary: true,
    },
    // shell
    shell: {
        displayName: 'Shell',
        primary: true,
        mimes: [ { mime: 'text/x-sh' } ],
    },
    sh: {
        primary: false,
        alias: 'shell',
    },
    bash: {
        displayName: 'Bash',
        primary: false,
        alias: 'shell',
    },
    powershell: {
        displayName: 'PowerShell',
        primary: true,
        mimes: [ { mime: 'application/x-powershell' } ],
    },
    // rest
    apl: {
        displayName: 'APL',
        primary: true,
        mimes: [ { mime: 'text/apl' } ],
    },
    asciiarmor: {
        displayName: 'ASCII Armor (PGP)',
        primary: true,
        mimes: [ { mime: 'application/pgp' } ],
    },
    'asn.1': {
        displayName: 'ASN.1',
        primary: true,
        mimes: [ { mime: 'text/x-ttcn-asn' } ],
    },
    asterisk: {
        displayName: 'Asterisk dialplan',
        primary: true,
        mimes: [ { mime: 'text/x-asterisk' } ],
    },
    clojure: {
        displayName: 'Clojure',
        primary: true,
        mimes: [ { mime: 'text/x-clojure' } ],
    },
    gss: {
        displayName: 'Closure Stylesheets (GSS)',
        primary: false,
        alias: 'css',
        mimes: [ { mime: 'text/x-gss' } ],
    },
    d: {
        displayName: 'D',
        primary: true,
        mimes: [ { mime: 'text/x-d' } ],
    },
    dart: {
        displayName: 'Dart',
        primary: true,
        mimes: [ { mime: 'application/dart' } ],
    },
    diff: {
        displayName: 'diff',
        primary: true,
        mimes: [ { mime: 'text/x-diff' } ],
    },
    dtd: {
        displayName: 'DTD',
        primary: true,
        mimes: [ { mime: 'application/xml-dtd' } ],
    },
    dylan: {
        displayName: 'Dylan',
        primary: true,
        mimes: [ { mime: 'text/x-dylan' } ],
    },
    ebnf: {
        displayName: 'EBNF',
        primary: true,
        options: {
            bracesMode: 'javascript',
        },
    },
    ecl: {
        displayName: 'ECL',
        primary: true,
        mimes: [ { mime: 'text/x-ecl' } ],
    },
    eiffel: {
        displayName: 'Eiffel',
        primary: true,
        mimes: [ { mime: 'text/x-eiffel' } ],
    },
    elm: {
        displayName: 'Elm',
        primary: true,
        mimes: [ { mime: 'text/x-elm' } ],
    },
    erlang: {
        displayName: 'Erlang',
        primary: true,
        mimes: [ { mime: 'text/x-erlang' } ],
    },
    factor: {
        displayName: 'Factor',
        primary: true,
        mimes: [ { mime: 'text/x-factor' } ],
    },
    fcl: {
        displayName: 'FCL',
        primary: true,
        mimes: [ { mime: 'text/x-fcl' } ],
    },
    forth: {
        displayName: 'Forth',
        primary: true,
        mimes: [ { mime: 'text/x-forth' } ],
    },
    fortran: {
        displayName: 'Fortran',
        primary: true,
        mimes: [ { mime: 'text/x-fortran' } ],
    },
    gas: {
        displayName: 'Gas',
        primary: true,
    },
    gherkin: {
        displayName: 'Gherkin',
        primary: true,
        mimes: [ { mime: 'text/x-feature' } ],
    },
    groovy: {
        displayName: 'Groovy',
        primary: true,
        mimes: [ { mime: 'text/x-groovy' } ],
    },
    haskell: {
        displayName: 'Haskell',
        primary: true,
        mimes: [ { mime: 'text/x-haskell' } ],
    },
    idl: {
        displayName: 'IDL',
        primary: true,
        mimes: [ { mime: 'text/x-idl' } ],
    },
    jinja2: {
        displayName: 'Jinja2',
        primary: true,
        options: { htmlMode: true },
    },
    julia: {
        displayName: 'Julia',
        primary: true,
        mimes: [ { mime: 'text/x-julia' } ],
    },
    livescript: {
        displayName: 'LiveScript',
        primary: true,
        mimes: [ { mime: 'text/x-livescript' } ],
    },
    lua: {
        displayName: 'Lua',
        primary: true,
        mimes: [ { mime: 'text/x-lua' } ],
    },
    mbox: {
        displayName: 'mbox',
        primary: true,
        mimes: [ { mime: 'application/mbox' } ],
    },
    mirc: {
        displayName: 'mIRC',
        primary: true,
        mimes: [ { mime: 'text/mirc' } ],
    },
    modelica: {
        displayName: 'Modelica',
        primary: true,
        mimes: [ { mime: 'text/x-modelica' } ],
    },
    mscgen: {
        displayName: 'MscGen',
        primary: true,
        mimes: [ { mime: 'text/x-mscgen' } ],
    },
    xu: {
        displayName: 'Xù',
        primary: false,
        alias: 'mscgen',
        mimes: [ { mime: 'text/x-xu' } ],
    },
    msgenny: {
        displayName: 'MsGenny',
        primary: false,
        alias: 'mscgen',
        mimes: [ { mime: 'text/x-msgenny' } ],
    },
    mumps: {
        displayName: 'MUMPS',
        primary: true,
    },
    nginx: {
        displayName: 'Nginx',
        primary: true,
    },
    nsis: {
        displayName: 'NSIS',
        primary: true,
        mimes: [ { mime: 'text/x-nsis' } ],
    },
    ntriples: {
        displayName: 'N-Triples',
        primary: true,
        // mimes: [ { mime: 'application/n-triples' } ],
    },
    nquads: {
        displayName: 'N-Quads',
        primary: false,
        alias: 'ntriples',
        mimes: [ { mime: 'application/n-quads' } ],
    },
    octave: {
        displayName: 'Octave',
        primary: true,
        mimes: [ { mime: 'text/x-octave' } ],
    },
    oz: {
        displayName: 'Oz',
        primary: true,
        mimes: [ { mime: 'text/x-oz' } ],
    },
    pascal: {
        displayName: 'Pascal',
        primary: true,
        mimes: [ { mime: 'text/x-pascal' } ],
    },
    pegjs: {
        displayName: 'PEG.js',
        primary: true,
    },
    perl: {
        displayName: 'Perl',
        primary: true,
        mimes: [ { mime: 'text/x-perl' } ],
    },
    pig: {
        displayName: 'Pig Latin',
        primary: true,
        mimes: [ { mime: 'text/x-pig' } ],
    },
    properties: {
        displayName: 'Properties file',
        primary: true,
        mimes: [ { mime: 'text/x-properties' } ],
    },
    ini: {
        displayName: 'INI',
        primary: false,
        alias: 'properties',
        mimes: [ { mime: 'text/x-ini' } ],
    },
    protobuf: {
        displayName: 'ProtoBuf',
        primary: true,
        mimes: [ { mime: 'text/x-protobuf' } ],
    },
    puppet: {
        displayName: 'Puppet',
        primary: true,
        mimes: [ { mime: 'text/x-puppet' } ],
    },
    q: {
        displayName: 'Q',
        primary: true,
        mimes: [ { mime: 'text/x-q' } ],
    },
    r: {
        displayName: 'R',
        primary: true,
    },
    rpm: {
        displayName: 'RPM changes',
        primary: true,
        mimes: [ { mime: 'text/x-rpm-changes' } ],
    },
    rpmspec: {
        displayName: 'RPM spec',
        primary: false,
        alias: 'rpm',
        mimes: [ { mime: 'text/x-rpm-spec' } ],
    },
    rst: {
        displayName: 'reStructuredText',
        primary: true,
        mimes: [ { mime: 'text/x-rst' } ],
    },
    rust: {
        displayName: 'Rust',
        primary: true,
        mimes: [ { mime: 'text/x-rustsrc' } ],
    },
    sas: {
        displayName: 'SAS',
        primary: true,
        mimes: [ { mime: 'text/x-sas' } ],
    },
    spreadsheet: {
        displayName: 'Spreadsheet',
        primary: true,
        mimes: [ { mime: 'text/x-spreadsheet' } ],
    },
    scheme: {
        displayName: 'Scheme',
        primary: true,
        mimes: [ { mime: 'text/x-scheme' } ],
    },
    sieve: {
        displayName: 'Sieve',
        primary: true,
        mimes: [ { mime: 'application/sieve' } ],
    },
    smalltalk: {
        displayName: 'Smalltalk',
        primary: true,
        mimes: [ { mime: 'text/x-stsrc' } ],
    },
    sparql: {
        displayName: 'SPARQL',
        primary: true,
        mimes: [ { mime: 'application/sparql-query' } ],
    },
    stex: {
        displayName: 'LaTeX',
        primary: true,
        mimes: [ { mime: 'text/x-stex' } ],
    },
    tcl: {
        displayName: 'Tcl',
        primary: true,
        mimes: [ { mime: 'text/x-tcl' } ],
    },
    textile: {
        displayName: 'Textile',
        primary: true,
        mimes: [ { mime: 'text/x-textile' } ],
    },
    toml: {
        displayName: 'TOML',
        primary: true,
        mimes: [ { mime: 'text/x-toml' } ],
    },
    troff: {
        displayName: 'troff',
        primary: true,
    },
    ttcn: {
        displayName: 'TTCN',
        primary: true,
        mimes: [ { mime: 'text/x-ttcn3' } ],
    },
    'ttcn-cfg': {
        displayName: 'TTCN-CFG',
        primary: true,
        mimes: [ { mime: 'text/x-ttcn-cfg' } ],
    },
    turtle: {
        displayName: 'Turtle',
        primary: true,
        mimes: [ { mime: 'text/turtle' } ],
    },
    verilog: {
        displayName: 'Verilog',
        primary: true,
        mimes: [ { mime: 'text/x-verilog' } ],
    },
    systemverilog: {
        displayName: 'SystemVerilog',
        primary: false,
        alias: 'verilog',
        mimes: [ { mime: 'text/x-systemverilog' } ],
    },
    vhdl: {
        displayName: 'VHDL',
        primary: true,
        mimes: [ { mime: 'text/x-vhdl' } ],
    },
    webidl: {
        displayName: 'Web IDL',
        primary: true,
        mimes: [ { mime: 'text/x-webidl' } ],
    },
    xquery: {
        displayName: 'XQuery',
        primary: true,
        mimes: [ { mime: 'application/xquery' } ],
    },
    yacas: {
        displayName: 'yacas',
        primary: true,
        mimes: [ { mime: 'text/x-yacas' } ],
    },
    z80: {
        displayName: 'Z80 assembly',
        primary: true,
        mimes: [ { mime: 'text/x-z80' } ],
    },
};
