import actionStates from './actionStates';
import API from '../../helpers/API';

import {
    actionTypes as collectionsActionTypes,
    addCollection,
    updateCollection,
    removeCollection,
} from './collections';


export const CategoryModel = (category = null) => ({
    id: (category && category.id) || 0,
    name: (category && category.name) || 'my public snippets',
});

const initialState = {
    status: actionStates.NOT_LOADED,
    selected: null,
    list: [],
};

export const actionTypes = {
    CATEGORIES_LOADING: 'CATEGORIES_LOADING',
    CATEGORIES_LOADED: 'CATEGORIES_LOADED',
    CATEGORIES_ERROR: 'CATEGORIES_ERROR',
    CATEGORY_SELECT: 'CATEGORY_SELECT',
    CATEGORY_INCREMENT: 'CATEGORY_INCREMENT',
    CATEGORY_DECREMENT: 'CATEGORY_DECREMENT',
    CATEGORY_ADD: 'CATEGORY_ADD',
    CATEGORY_UPDATE: 'CATEGORY_UPDATE',
    CATEGORY_REMOVE: 'CATEGORY_REMOVE',
};


export const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CATEGORIES_LOADING:
            return {
                ...state,
                status: actionStates.LOADING,
            };
        case actionTypes.CATEGORIES_ERROR:
            return {
                ...state,
                status: actionStates.ERROR,
            };
        case actionTypes.CATEGORIES_LOADED:
            return {
                ...state,
                status: actionStates.LOADED,
                list: action.categories.map(category => (!category.id ? { ...category, name: 'my public snippets' } : category)),
            };
        case actionTypes.CATEGORY_SELECT:
            return {
                ...state,
                selected: action.id ? parseInt(action.id, 10) : null,
            };
        case actionTypes.CATEGORY_INCREMENT:
            return {
                ...state,
                list: state.list.map(c => {
                    if (c.id === action.id) {
                        c.snippets_count += 1;
                    }
                    return c;
                }),
            };
        case actionTypes.CATEGORY_DECREMENT:
            return {
                ...state,
                list: state.list.map(c => {
                    if (c.id === action.id && c.snippets_count > 0) {
                        c.snippets_count -= 1;
                    }
                    return c;
                }),
            };
        case actionTypes.CATEGORY_ADD:
            return {
                ...state,
                list: [...state.list, action.category],
                selected: action.category.id || null,
            };
        case actionTypes.CATEGORY_UPDATE:
            return {
                ...state,
                list: state.list.map((c) => {
                    if (c.id === action.category.id) {
                        return {
                            ...c,
                            ...action.category
                        };
                    }
                    return c;
                }),
            };
        case actionTypes.CATEGORY_REMOVE:
            return {
                ...state,
                list: state.list.filter(c => c.id !== action.id),
            };

        case collectionsActionTypes.COLLECTION_ADD:
            return {
                ...state,
                list: [],
                selected: null,
            };

        default:
            return state;
    }
};


export const fetchCategories = (categoryId = null) => (dispatch) => {
    const params = categoryId !== null ? `/${categoryId}` : '';

    API(dispatch).call({
        url: `categories${params}`,
        beforeLoad: () => dispatch({ type: actionTypes.CATEGORIES_LOADING }),
        onSuccess: response => dispatch({
            type: actionTypes.CATEGORIES_LOADED,
            categories: response.categories,
        }),
        onFail: error => dispatch({ type: actionTypes.CATEGORIES_ERROR }),
    });
};

export const selectCategory = id => ({ type: actionTypes.CATEGORY_SELECT, id });
export const addCategory = category => ({ type: actionTypes.CATEGORY_ADD, category });
export const updateCategory = category => ({ type: actionTypes.CATEGORY_UPDATE, category });
export const removeCategory = id => ({ type: actionTypes.CATEGORY_REMOVE, id });
export const categoryIncrement = id => ({ type: actionTypes.CATEGORY_INCREMENT, id });
export const categoryDecrement = id => ({ type: actionTypes.CATEGORY_DECREMENT, id });

export const saveCategory = (category, afterSave = null) => (dispatch) => {
    const isNew = category.id < 1;
    let categoryData = {
        category_id: (isNew ? null : (category.bookmark_for || category.id)),
        parent_id: (category.parent_id || null),
        is_collection: (category.is_collection || null),
        name: category.name.trim(),
        description: (category.description || null),
        collection_color: (category.collection_color || null),
        visibility: category.visibility || 'public',
    };

    API(dispatch).call({
        url: 'categories/save',
        method: 'POST',
        data: categoryData,
        onSuccess: (response) => {
            if (response.status === 'ok') {
                let newCategory = {
                    ...response.category,
                    isNew: isNew,
                    snippets_count: category.snippets_count || 0,
                };

                if (isNew) {
                    if (newCategory.is_collection) {
                        dispatch(addCollection(newCategory));
                    } else {
                        dispatch(addCategory(newCategory));
                    }
                }
                else {
                    if (newCategory.is_collection) {
                        dispatch(updateCollection(newCategory));
                    } else {
                        dispatch(updateCategory(newCategory));
                    }
                }

                if (typeof afterSave === 'function') {
                    afterSave(newCategory);
                }
            }
            else {
                alert(response && response.message);
            }
        },
        onFail: error => dispatch({ type: actionTypes.CATEGORIES_ERROR }),
    });
};


export const deleteCategory = (category, afterDelete = null) => (dispatch) => {
    API(dispatch).call({
        url: 'categories/delete',
        method: 'POST',
        data: {
            category_id: category.id,
        },
        onSuccess: (response) => {
            if (response.status === 'ok') {
                if (category.is_collection) {
                    dispatch(removeCollection(category.id));
                } else {
                    dispatch(removeCategory(category.id));
                }

                if (typeof afterDelete === 'function') {
                    afterDelete(category);
                }
            } else {
                alert(response && response.message);
            }
        },
        onFail: error => dispatch({ type: actionTypes.CATEGORIES_ERROR }),
    })
};

export const toggleShare = (category, user_id = null) => (dispatch) => {
    API(dispatch).call({
        url: 'sharing',
        method: 'POST',
        data: {
            categoryId: category.bookmark_for || category.id,
            userId: category.bookmark_for ? null : user_id,
        },
        onSuccess: (response) => {
            if (response.status === 'ok') {
                if (category.bookmark_for) {
                    if (response.category.is_collection) {
                        dispatch(removeCollection(category.id))
                    } else {
                        dispatch(removeCategory(category.id))
                    }
                } else {
                    if (response.category.is_collection) {
                        dispatch(updateCollection(response.category));
                    } else {
                        dispatch(updateCategory(response.category));
                    }
                }

            } else {
                alert(response && response.message);
            }
        },
        onFail: error => dispatch({ type: actionTypes.CATEGORIES_ERROR }),
    })
};
