import {
    cmAddons,
    getAddonFile,
    getModeFile,
    getFlatDependencies,
    LoadCM,
    LoadCMAddon,
    LoadCMMode, cmCSS, cmJS,
} from '../../helpers/cm';

const initialState = {
    cmLoaded: false,
    modes: {},
};

const actionTypes = {
    CM_LOADED: 'CM_LOADED',
    CM_MODE_LOADING: 'CM_MODE_LOADING',
    CM_MODE_LOADED: 'CM_MODE_LOADED',
};

export const cmReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CM_LOADED:
            return {
                ...state,
                cmLoaded: true,
            };
        case actionTypes.CM_MODE_LOADING:
            return {
                ...state,
                modes: { ...state.modes, [action.mode]: false }
            };
        case actionTypes.CM_MODE_LOADED:
            return {
                ...state,
                modes: { ...state.modes, [action.mode]: true }
            };
        default:
            return state;
    }
};


export const cmLoaded = () => ({ type: actionTypes.CM_LOADED });
export const cmModeLoading = mode => ({ type: actionTypes.CM_MODE_LOADING, mode });
export const cmModeLoaded = mode => ({ type: actionTypes.CM_MODE_LOADED, mode });


export const cmLoad = (callback = () => {}) => (dispatch, getState) => {
    const { cm } = getState();

    if (!cm.cmLoaded) {
        LoadCM()
            .then(() => Promise.all(
                cmAddons.map(a => LoadCMAddon(getAddonFile(a)))
            ))
            .then(() => dispatch(cmLoaded()))
            .then(() => callback())
            .catch(error=> console.warn(error));
    } else {
        callback();
    }
};

export const cmGetMode = mode => (dispatch, getState) => {
    const { cm } = getState();

    if (typeof cm.modes[mode] === 'undefined') {
        const dependencies = getFlatDependencies(mode);

        if (dependencies.length) {
            const missingDependencies = dependencies.filter(d => (typeof cm.modes[d] === 'undefined'));

            const resolve = missingDependencies.map(d => LoadCMMode(getModeFile(d)));

            dispatch(cmModeLoading(mode));
            Promise
                .all(resolve)
                .then(() => {
                    missingDependencies.map(d => dispatch(cmModeLoaded(d)));
                    dispatch(cmModeLoaded(mode));
                })
                .catch(err => console.warn(err));
        }
    }
};

export const preloadAll = (mode) => (dispatch) => new Promise((resolve, reject) => {
    const modes = getFlatDependencies(mode);

    modes.map(d => dispatch(cmModeLoaded(d)));
    dispatch(cmModeLoaded(mode));
    dispatch(cmLoaded());

    resolve([
        cmJS,
        cmCSS,
        ...(cmAddons.map(a => getAddonFile(a))),
        ...(modes.map(d => getModeFile(d))),
    ]);
});
