import API from '../../helpers/API';
import { ScriptLoader } from '../../helpers/DOM';


const initialState = {
    braintreeLoaded: false,
    userInfoLoading: true,
    userInfo: null,
    paymentLoading: false,
    paymentError: null,
    subscription: null,
};

const actionTypes = {
    BT_LOADED: 'BT_LOADED',
    BT_USER_INFO_LOADING: 'BT_USER_INFO_LOADING',
    BT_USER_INFO_LOADED: 'BT_USER_INFO_LOADED',
    BT_USER_INFO_SET: 'BT_USER_INFO_SET',
    BT_PAYMENT_LOADING: 'BT_PAYMENT_LOADING',
    BT_PAYMENT_LOADED: 'BT_PAYMENT_LOADED',
    BT_SET_PAYMENT_ERROR: 'BT_SET_PAYMENT_ERROR',
    BT_SET_SUBSCRIPTION_DETAILS: 'BT_SET_SUBSCRIPTION_DETAILS',
};

export const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BT_LOADED:
            return {
                ...state,
                braintreeLoaded: true,
            };
        case actionTypes.BT_USER_INFO_LOADING:
            return {
                ...state,
                userInfoLoading: true,
            };
        case actionTypes.BT_USER_INFO_LOADED:
            return {
                ...state,
                userInfoLoading: false,
                userInfo: action.userInfo,
            };

        case actionTypes.BT_USER_INFO_SET:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    [action.field]: action.value,
                },
            };

        case actionTypes.BT_PAYMENT_LOADING:
            return {
                ...state,
                paymentLoading: true,
            };

        case actionTypes.BT_PAYMENT_LOADED:
            return {
                ...state,
                paymentLoading: false,
            };

        case actionTypes.BT_SET_PAYMENT_ERROR:
            return {
                ...state,
                paymentError: action.message,
            };

        case actionTypes.BT_SET_SUBSCRIPTION_DETAILS:
            return {
                ...state,
                subscription: action.subscription,
            };
        default:
            return state;
    }
};


export const btLoad = () => dispatch => {
    ScriptLoader('https://js.braintreegateway.com/web/dropin/1.9.0/js/dropin.min.js')
        .then(() => dispatch({ type: actionTypes.BT_LOADED }));
};

export const setSubscription = subscription => ({
    type: actionTypes.BT_SET_SUBSCRIPTION_DETAILS,
    subscription: subscription,
});

export const setPaymentError = (message = null) => ({
    type: actionTypes.BT_SET_PAYMENT_ERROR,
    message: message,
});

export const fetchUserInfo = () => (dispatch, getState) => {
    API(dispatch).call({
        url: 'payment/get_customer',
        beforeLoad: () => dispatch({ type: actionTypes.BT_USER_INFO_LOADING }),
        onSuccess: (response) => {
            dispatch({
                type: actionTypes.BT_USER_INFO_LOADED,
                userInfo: {
                    email: getState().user.email,
                    ...(response.customer || null),
                    token: response.token || null,
                },
            });
        },
        onFail: error => { console.log(error); },
    })
};

export const updateUserInfo = (field, value) => ({ type: actionTypes.BT_USER_INFO_SET, field, value });

export const saveUserInfo = (callback = () => {}) => (dispatch, getState) => {
    const userData = getState().payment.userInfo;

    API(dispatch).call({
        url: 'payment/create_customer',
        method: 'POST',
        data: { ...userData },
        beforeLoad: () => dispatch({ type: actionTypes.BT_USER_INFO_LOADING }),
        onSuccess: (response) => {
            if (response.status === 'ok') {
                callback();
            }
        },
        onFail: error => { console.log(error); },
    })
};

export const createSubscription = (subscription, payload, callback = () => {}) => (dispatch) => {
    API(dispatch).call({
        url: 'payment/subscribe',
        method: 'POST',
        data: {
            plan: subscription.plan,
            billing: subscription.billing,
            nonceFromClient: payload.nonce,
            deviceData: payload.deviceData,
        },
        beforeLoad: () => {
            dispatch({ type: actionTypes.BT_PAYMENT_LOADING });
            dispatch(setPaymentError(null));
        },
        onSuccess: (response) => {
            if (response.status === 'ok') {
                dispatch(setSubscription(response.subscription));
                dispatch({ type: actionTypes.BT_PAYMENT_LOADED });

                // delay the callback a bit
                setTimeout(callback, 300);
            } else {
                dispatch({ type: actionTypes.BT_PAYMENT_LOADED });

                if (response.error && response.error.reason === 'fraud') {
                    dispatch(setPaymentError("Your card could not be verified. Please try again with another one."));
                } else {
                    dispatch(setPaymentError("We were unable to process this request. Please give us another chance and try again. :)"));
                }
            }
        },
        onFail: error => { console.log(error); },
    });
};


export const fetchSubscription = (callback) => (dispatch) => {
    API(dispatch).call({
        url: 'payment/get_subscription',
        beforeLoad: () => {},
        onSuccess: (response) => {
            if (response.status === 'ok') {
                dispatch(setSubscription(response.subscription));
                callback();
            }
        },
        onFail: error => { console.log(error); },
    });
};


export const cancelSubscription = (callback) => (dispatch) => {
    API(dispatch).call({
        url: 'payment/cancel_subscription',
        method: 'POST',
        beforeLoad: () => {},
        onSuccess: (response) => {
            if (response.status === 'ok') {
                dispatch(setSubscription(response.subscription));
                callback();
            }
        },
        onFail: error => { console.log(error); },
    });
};
