import API from '../../helpers/API';

const initialState = {
    shares: [],
    users: [],
    isLoadingShares: false,
    isLoadingUsers: false,
};

const actionTypes = {
    CLEAR_SHARES: 'SHARING_CLEAR_SHARES',
    CLEAR_USERS: 'SHARING_CLEAR_USERS',
    LOADING_SHARES: 'SHARING_LOADING_SHARES',
    LOADING_USERS: 'SHARING_LOADING_USERS',
    SET_SHARES: 'SHARING_SET_SHARES',
    SET_USERS: 'SHARING_SET_USERS',
    ADD_SHARE: 'SHARING_ADD_SHARE',
    REMOVE_SHARE: 'SHARING_REMOVE_SHARE',
    TOGGLE_SHARE: 'SHARING_TOGGLE_SHARE',
};

export const sharingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_SHARES:
            return {
                ...state,
                shares: [],
            };
        case actionTypes.CLEAR_USERS:
            return {
                ...state,
                users: [],
            };

        case actionTypes.LOADING_SHARES:
            return {
                ...state,
                isLoadingShares: action.loading,
            };
        case actionTypes.LOADING_USERS:
            return {
                ...state,
                isLoadingUsers: action.loading,
            };

        case actionTypes.SET_SHARES:
            return {
                ...state,
                shares: action.shares,
                isLoadingShares: false,
            };
        case actionTypes.SET_USERS:
            return {
                ...state,
                users: action.users,
                isLoadingUsers: false,
            };

        case actionTypes.ADD_SHARE:
            return {
                ...state,
                shares: [
                    ...state.shares,
                    action.share,
                ],
            };

        case actionTypes.REMOVE_SHARE:
            return {
                ...state,
                shares: state.shares.filter(s => s.id !== action.id),
            };

        case actionTypes.TOGGLE_SHARE:
            return {
                ...state,
                shares: state.shares.map(s => {
                    if (s.id === action.id) {
                        return {
                            ...s,
                            canWrite: action.toggle,
                        };
                    }

                    return s;
                }),
            };

        default:
            return state;
    }
};


export const setUsers = (users, excludeItems = []) => ({
    type: actionTypes.SET_USERS,
    users: users.filter(u => excludeItems.filter(s => s.id === u.id).length === 0),
});

export const clearShares = () => ({ type: actionTypes.CLEAR_SHARES });
export const clearUsers = () => ({ type: actionTypes.CLEAR_USERS });

export const addShare = share => ({ type: actionTypes.ADD_SHARE, share });
export const removeShare = id => ({ type: actionTypes.REMOVE_SHARE, id });
export const toggleShare = (id, toggle) => ({ type: actionTypes.TOGGLE_SHARE, id, toggle });

const loadingShares = (loading = true) => ({ type: actionTypes.LOADING_SHARES, loading });
export const loadingUsers = (loading = true) => ({ type: actionTypes.LOADING_USERS, loading });

export const fetchShares = category_id => (dispatch) => {
    API(dispatch).call({
        url: 'sharing/shares',
        method: 'POST',
        data: { id: category_id },
        beforeLoad: () => dispatch(loadingShares()),
        onSuccess: response => dispatch({
            type: actionTypes.SET_SHARES,
            shares: response.shares,
        }),
        onFail: (error) => {
            dispatch(loadingShares(false));
            console.error(error);
        },
    });
};

export const fetchUsers = q => (dispatch, getState) => {
    const { sharing } = getState();
    const shares = sharing.shares;

    API(dispatch).call({
        url: 'sharing/users',
        method: 'POST',
        data: { q: q },
        beforeLoad: () => dispatch(loadingUsers()),
        onSuccess: response => dispatch(setUsers(response.users, shares)),
        onFail: (error) => {
            dispatch(loadingUsers(false));
            console.error(error);
        },
    });
};

export const toggleShareAccess = (category_id, user) => (dispatch) => {
    dispatch(toggleShare(user.id, !user.canWrite));

    API(dispatch).call({
        url: 'sharing/toggle',
        method: 'POST',
        data: {
            categoryId: category_id,
            userId: user.id,
        },
        beforeLoad: () => {},
        onSuccess: (response) => {
            if (response.status !== 'ok' || user.canWrite === !!response.bookmark_write) {
                // revert to initial state
                dispatch(toggleShare(user.id, !!response.bookmark_write));
            }
        },
        onFail: (error) => {
            // revert to initial state
            dispatch(toggleShare(user.id, user.canWrite));
            console.error(error);
        }
    })
};
