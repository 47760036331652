import config from '../config/environments';
import { modes } from './cm-modes';
import { ScriptLoader, StylesLoader } from './DOM';


export const cmJS = `${config.application.cdnUrl}/${config.libraries.cm}/codemirror.js`;
export const cmCSS = `${config.application.cdnUrl}/${config.libraries.cm}/codemirror.css`;
export const getModeFile = mode => `${config.application.cdnUrl}/${config.libraries.cm}/mode/${mode}/${mode}.js`;
export const getAddonFile = addon => `${config.application.cdnUrl}/${config.libraries.cm}/addon/${addon}.js`;

export const cmAddons = [
    /*/
    'mode/simple',
    'mode/overlay',
    'fold/xml-fold', // required by 'edit/matchtags'
    'edit/closebrackets',
    'edit/closetag',
    'edit/matchbrackets',
    'edit/matchtags',
    'edit/trailingspace',
    /*/
    // all of the above are bundled into a single 'bundle/cmaddons',
    '_bundle/cmaddons',
    //*/
];

export const getFlatDependencies = (mode) => {
    const dependencies = [];

    if (modes[mode]) {
        const m = modes[mode];
        if (m.dependencies) {
            for (let i = 0, n = m.dependencies.length; i < n; i++) {
                dependencies.push(...getFlatDependencies(m.dependencies[i]));
            }
        }

        if (m.alias) {
            dependencies.push(...getFlatDependencies(m.alias));
        }

        if (m.primary) {
            dependencies.push(mode);
        }
    }

    return dependencies;
};


export const getNameOrMime = (mode, code) => {
    if (!modes[mode]) {
        return null;
    }

    let m = modes[mode];

    if (!m.mimes && m.alias && modes[m.alias].mimes) {
        m = modes[m.alias];
    }

    if (m.mimes) {
        for (let i = 0, n = m.mimes.length; i < n; i++) {
            if ((i === n - 1) || (m.mimes[i].match && code.toLowerCase().indexOf(m.mimes[i].match) !== -1)) {
                return m.mimes[i].mime;
            }
        }
    }

    return mode;
};


export const getAvailableModes = () =>
    Object.keys(modes)
        .filter(k => modes[k].displayName)
        .map(k => ({ id: k, name: modes[k].displayName }))
        .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));


export const getMode = alias => (
    alias &&
    getAvailableModes()
        .find(m => m.id === alias
            .replace('plain-text', 'text')
            .replace('-', '')
        )
);

export const LoadCM = () => {
    return Promise.all([
        ScriptLoader(cmJS),
        StylesLoader(cmCSS),
    ]);
};

export const LoadCMMode = modeFile => ScriptLoader(modeFile);
export const LoadCMAddon = addonFile => ScriptLoader(addonFile);
