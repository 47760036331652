import actionStates from './actionStates';
import API from '../../helpers/API';

const initialState = {
    status: actionStates.NOT_LOADED,
    selected: null,
    list: [],
};

export const actionTypes = {
    COLLECTIONS_LOADING: 'COLLECTIONS_LOADING',
    COLLECTIONS_LOADED: 'COLLECTIONS_LOADED',
    COLLECTIONS_ERROR: 'COLLECTIONS_ERROR',
    COLLECTION_SELECT: 'COLLECTION_SELECT',
    COLLECTION_ADD: 'COLLECTION_ADD',
    COLLECTION_UPDATE: 'COLLECTION_UPDATE',
    COLLECTION_REMOVE: 'COLLECTION_REMOVE',
};


export const collectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COLLECTIONS_LOADING:
            return {
                ...state,
                status: actionStates.LOADING,
            };
        case actionTypes.COLLECTIONS_ERROR:
            return {
                ...state,
                status: actionStates.ERROR,
            };
        case actionTypes.COLLECTIONS_LOADED:
            return {
                ...state,
                status: actionStates.LOADED,
                list: [ ...action.collections ],
            };
        case actionTypes.COLLECTION_SELECT:
            return {
                ...state,
                selected: action.id ? parseInt(action.id, 10) : null,
            };
        case actionTypes.COLLECTION_ADD:
            return {
                ...state,
                list: [...state.list, action.collection],
                selected: action.collection.id || null,
            };
        case actionTypes.COLLECTION_UPDATE:
            return {
                ...state,
                list: state.list.map((c) => {
                    if (c.id === action.collection.id) {
                        return {
                            ...c,
                            ...action.collection,
                        };
                    }
                    return c;
                }),
            };

        case actionTypes.COLLECTION_REMOVE:
            return {
                ...state,
                list: state.list.filter(c => c.id !== action.id),
                selected: null,
            };
        default:
            return state;
    }
};


export const fetchCollections = () => dispatch => {
    API(dispatch).call({
        url: 'collections',
        beforeLoad: () => dispatch({ type: actionTypes.COLLECTIONS_LOADING }),
        onSuccess: response => dispatch({
            type: actionTypes.COLLECTIONS_LOADED,
            collections: response.categories,
        }),
        onFail: error => dispatch({ type: actionTypes.COLLECTIONS_ERROR }),
    });
};

export const selectCollection = (id) => ({ type: actionTypes.COLLECTION_SELECT, id });
export const addCollection = collection => ({ type: actionTypes.COLLECTION_ADD, collection });
export const updateCollection = collection => ({ type: actionTypes.COLLECTION_UPDATE, collection });
export const removeCollection = id => ({ type: actionTypes.COLLECTION_REMOVE, id });
