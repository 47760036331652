module.exports = {
    application: {
        baseUrl: 'https://snipit.io',
        cdnUrl: 'https://cdn.snipit.io',
    },
    api: {
        baseUrl: 'https://snipit.io/api',
        authUrl: 'https://snipit.io/auth',
    },
    braintree: {
        token: null,
    },
};
