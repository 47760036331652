
const DROPDOWN_ADD = 'DROPDOWN_ADD';
const DROPDOWN_REMOVE = 'DROPDOWN_REMOVE';
const DROPDOWN_OPEN = 'DROPDOWN_OPEN';
const DROPDOWN_CLOSE = 'DROPDOWN_CLOSE';
const DROPDOWN_CLOSE_ALL = 'DROPDOWN_CLOSE_ALL';

export const addDropdown = (id) => ({ type: DROPDOWN_ADD, id });
export const removeDropdown = (id) => ({ type: DROPDOWN_REMOVE, id });
export const openDropdown = (id) => ({ type: DROPDOWN_OPEN, id });
export const closeDropdown = (id) => ({ type: DROPDOWN_CLOSE, id });
export const closeAllDropdowns = () => ({ type: DROPDOWN_CLOSE_ALL });

export const dropdownReducer = (state = [{id: 0, isOpen: false}], action) => {
    switch (action.type) {
        case DROPDOWN_ADD: {
            return [ ...state, { id: action.id, isOpen: false } ];
        }

        case DROPDOWN_REMOVE: {
            return state.filter(item => item.id !== action.id);
        }

        case DROPDOWN_OPEN: {
            return state.map(item => ({ ...item, isOpen: (item.id === action.id) }));
        }

        case DROPDOWN_CLOSE: {
            return state.map((item) => {
                if (item.id === action.id) {
                    item.isOpen = false;
                }
                return item;
            });
        }

        case DROPDOWN_CLOSE_ALL: {
            return state.map( item => ({ ...item, isOpen: false }));
        }

        default: return state;
    }
};