import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import { Subject as RxSubject } from 'rxjs/Subject';
import { Observable as RxObservable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/switchMap';

import { FakeComponent } from './ui/seed';

import { checkAuth } from './store/actions/user';
import { TrackPageView } from './helpers/tracker';


const Landing = Loadable({
    loader: () => import(/* webpackChunkName: "homePage"*/ './pages/Landing'),
    loading: () => null,
    modules: ['homePage'],
});
const Application = Loadable({
    loader: () => import(/* webpackChunkName: "appPage"*/ './pages/Application'),
    loading: () => null,
    modules: ['appPage'],
});
const Embeddable = Loadable({
    loader: () => import(/* webpackChunkName: "embed"*/ './panels/embed/EmbedContainer'),
    loading: () => null,
    modules: ['embed'],
});


class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
        };

        this.historyListener = null;

        this.checkAuth$ = new RxSubject();
        this.authPing$ = this.checkAuth$
            .switchMap(isAuthenticated =>
                isAuthenticated
                    ? RxObservable.timer(0, 10000) // 10 seconds
                    : RxObservable.timer(0, 5000) // 5 seconds
            )
    }

    componentDidMount() {
        if(this.props.history.location.pathname.indexOf('/embed') < 0) {
            this.authPing$.subscribe(this.props.checkAuth);
            this.checkAuth$.next(this.props.authenticated);
        }

        TrackPageView(this.props.history.location, this.props.history.action);
        this.historyListener = this.props.history.listen(TrackPageView);

        this.setState({ isLoaded: true });
    }

    componentWillUnmount() {
        this.authPing$.unsubscribe();
        this.historyListener();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authenticated !== this.props.authenticated) {
            this.checkAuth$.next(nextProps.authenticated);
        }
    }

    render() {
        return (
            <>
                <Switch>
                    <Route path="/embed/:snippetId?" component={Embeddable} />
                    <Route path="/" component={this.props.authenticated ? Application : Landing} />
                </Switch>
                {!this.state.isLoaded ? <FakeComponent /> : null}
            </>
        );
    }
}

export default withRouter(
    connect(
        ({ user }) => ({
            authenticated: user.authenticated,
        }),
        dispatch => ({
            checkAuth() {
                dispatch(checkAuth());
            },
        }),
    )(App)
);
