const initialState = {
    mainPanelWidth: 280,
    snippetsPanelWidth: 380,
};

const actionTypes = {
    MAIN_PANEL_WIDTH: 'MAIN_PANEL_WIDTH',
    SNIPPETS_PANEL_WIDTH: 'SNIPPETS_PANEL_WIDTH',
};

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MAIN_PANEL_WIDTH:
            return {
                ...state,
                mainPanelWidth: action.width,
            };
        case actionTypes.SNIPPETS_PANEL_WIDTH:
            return {
                ...state,
                snippetsPanelWidth: action.width,
            };
        default:
            return state;
    }
};


export const setMainPanelWidth = width => ({ type: actionTypes.MAIN_PANEL_WIDTH, width });
export const setSnippetsPanelWidth = width => ({ type: actionTypes.SNIPPETS_PANEL_WIDTH, width });
