import actionStates from './actionStates';
import API from '../../helpers/API';
import { SnippetModel } from './snippets';

const initialState = {
    query: null,
    status: actionStates.NOT_LOADED,
    snippetsList: [],
    selectedId: null,
};

const actionTypes = {
    SET_QUERY: 'SET_QUERY',
    SNIPPETS_LOADING: 'SEARCH.SNIPPETS_LOADING',
    SNIPPETS_LOADED: 'SEARCH.SNIPPETS_LOADED',
    SNIPPETS_ERROR: 'SEARCH.SNIPPETS_ERROR',
    SNIPPET_SELECT: 'SEARCH.SNIPPET_SELECT',
};


export const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_QUERY:
            return {
                ...state,
                query: action.query,
                ...(action.query === null && {
                    status: actionStates.NOT_LOADED,
                    snippetsList: [],
                    selectedId: null,
                })
            };
        case actionTypes.SNIPPETS_LOADING:
            return {
                ...state,
                status: actionStates.LOADING,
                snippetsList: [],
                selectedId: null,
            };
        case actionTypes.SNIPPETS_ERROR:
            return {
                ...state,
                status: actionStates.ERROR,
            };
        case actionTypes.SNIPPETS_LOADED:
            return {
                ...state,
                status: actionStates.LOADED,
                snippetsList: [ ...action.snippets ],
                selectedId: action.snippets.length ? action.snippets[0].id : null,
            };
        case actionTypes.SNIPPET_SELECT:
            return {
                ...state,
                selectedId: action.id ? parseInt(action.id, 10) : null,
            };

        default:
            return state;
    }
};


export const searchSnippets = searchQuery => dispatch => {
    API(dispatch).call({
        url: `snippets/search`,
        method: 'POST',
        data: { q: searchQuery },
        beforeLoad: () => dispatch({ type: actionTypes.SNIPPETS_LOADING }),
        onSuccess: response => {
            const sortedSnippets = [ ...response.snippets ];
            sortedSnippets.sort((a, b) => a.search_score >= b.search_score ? -1 : 1);

            dispatch({
                type: actionTypes.SNIPPETS_LOADED,
                snippets: sortedSnippets.map(s => SnippetModel(s)),
            });
        },
        onFail: error => dispatch({ type: actionTypes.SNIPPETS_ERROR }),
    });
};

export const setSearchQuery = q => ({ type: actionTypes.SET_QUERY, query: q });
export const selectSnippet = id => ({ type: actionTypes.SNIPPET_SELECT, id });
