
const DIALOG_ADD = 'DIALOG_ADD';
const DIALOG_REMOVE = 'DIALOG_REMOVE';
const DIALOG_OPEN = 'DIALOG_OPEN';
const DIALOG_CLOSE = 'DIALOG_CLOSE';
const DIALOG_TOGGLE = 'DIALOG_TOGGLE';
const DIALOG_CLOSE_ALL = 'DIALOG_CLOSE_ALL';

export const addDialog = (id) => ({ type: DIALOG_ADD, id });
export const removeDialog = (id) => ({ type: DIALOG_REMOVE, id });
export const openDialog = (id) => ({ type: DIALOG_OPEN, id });
export const closeDialog = (id) => ({ type: DIALOG_CLOSE, id });
export const toggleDialog = (id) => ({ type: DIALOG_TOGGLE, id });
export const closeAllDialogs = () => ({ type: DIALOG_CLOSE_ALL });

export const dialogReducer = (state = [{id: 0, isOpen: false}], action) => {
    switch (action.type) {
        case DIALOG_ADD: {
            return [ ...state, { id: action.id, isOpen: false } ];
        }

        case DIALOG_REMOVE: {
            return state.filter(item => !item.id || item.id !== action.id);
        }

        case DIALOG_OPEN: {
            return state.map(item => ({ ...item, isOpen: (item.id === action.id) }));
        }

        case DIALOG_CLOSE: {
            return state.map((item) => {
                if (item.id === action.id) {
                    item.isOpen = false;
                }
                return item;
            });
        }

        case DIALOG_TOGGLE: {
            return state.map((item) => {
                if (item.id === action.id) {
                    item.isOpen = !item.isOpen;
                }
                return item;
            });
        }

        case DIALOG_CLOSE_ALL: {
            return state.map( item => ({ ...item, isOpen: false }));
        }

        default: return state;
    }
};
