export const handleBadResponse = response => dispatch => {
    if (!response.ok) {
        switch(response.status) {
            // TODO: Handle different error types
            case 403:
                // TODO: Handle Unauthorized request
                console.warn('403', response.statusText, "--- User must be authenticated to access this resource.");

                break;
            default:
                console.warn('4xx,5xx', response.statusText);
        }

        // TODO: implement some APP-level error handling action
        dispatch({ type: 'NONE' });
    }
};
