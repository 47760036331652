module.exports = {
    application: {
        baseUrl: 'https://localhost:3000',
        cdnUrl: '',
    },
    api: {
        baseUrl: 'https://localhost:3000/api',
        authUrl: 'https://localhost:3000/auth',
    },
    braintree: {
        token: 'sandbox_xd7qwpfv_tsp5km9rmz8k5k5k',
    },
};
