const env = process.env.NODE_ENV || "development";

let config = {};

switch(env) {
    case "development":
        config = require('./development');
        break;
    case "test":
        config = require('./test');
        break;
    case "production":
        config = require('./production');
        break;
    default:
}

config.libraries = {
    cm: 'libraries/codemirror-5.53.2',
};


module.exports = config;
// export default config;
