import { styled } from 'styletron-react';

export const FakeComponent = styled('div', {
    color: 'white',
    '@media (max-width: 567px)': { color: 'white' },
    '@media (min-width: 568px)': { color: 'white' },
    '@media (max-width: 640px)': { color: 'white' },
    '@media (max-width: 767px)': { color: 'white' },
    '@media (min-width: 768px)': { color: 'white' },
    '@media screen and (min-width: 1000px)': { color: 'white' },
    '@media screen and (min-width: 1280px)': { color: 'white' },
});
