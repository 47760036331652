import { Client, Server } from 'styletron-engine-atomic';

let styletronInstance;

if (typeof window !== 'undefined') {
    styletronInstance = new Client({ hydrate: document.getElementsByClassName('_styletron_hydrate_') });
} else {
    styletronInstance = new Server();
}

export default styletronInstance;
