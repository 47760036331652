import config from '../config/environments';
import { handleBadResponse } from '../store/actions/handleApiResponses';


export default (dispatch) => ({
    call({
        url,
        method = 'GET',
        data = null,
        beforeLoad = () => {},
        onSuccess = () => {},
        onFail = () => {},
    }) {
        // eslint-disable-next-line
        let fetch = fetch || global.fetch || null;

        if (!fetch) {
            return;
        }

        beforeLoad();

        const hash = `${url.indexOf('?') !== -1 ? '&' : '?'}rand=${Math.floor(Math.random() * 100000)}`;

        fetch(`${config.api.baseUrl}/${url}${hash}`, {
            method: method,
            credentials: 'include',
            ...(data && {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }),
            ...(global.agent && { agent: global.agent }),
        })
            .then(response => {
                if (dispatch) {
                    dispatch(handleBadResponse(response));
                }

                return response.json();
            })
            .then(response => {
                onSuccess(response);
            })
            .catch(error => {
                console.error("Crashed with", error);
                // TODO: implement another app generic error handler
                onFail(error);
            });
    },
});
