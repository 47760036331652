
const POPOVER_ADD = 'POPOVER_ADD';
const POPOVER_REMOVE = 'POPOVER_REMOVE';
const POPOVER_OPEN = 'POPOVER_OPEN';
const POPOVER_CLOSE = 'POPOVER_CLOSE';
const POPOVER_TOGGLE = 'POPOVER_TOGGLE';
const POPOVER_CLOSE_ALL = 'POPOVER_CLOSE_ALL';

export const addPopover = (id) => ({ type: POPOVER_ADD, id });
export const removePopover = (id) => ({ type: POPOVER_REMOVE, id });
export const openPopover = (id) => ({ type: POPOVER_OPEN, id });
export const closePopover = (id) => ({ type: POPOVER_CLOSE, id });
export const togglePopover = (id) => ({ type: POPOVER_TOGGLE, id });
export const closeAllPopovers = () => ({ type: POPOVER_CLOSE_ALL });

export const popoverReducer = (state = [{id: 0, isOpen: false}], action) => {
    switch (action.type) {
        case POPOVER_ADD: {
            return [ ...state, { id: action.id, isOpen: false } ];
        }

        case POPOVER_REMOVE: {
            return state.filter(item => !item.id || item.id !== action.id);
        }

        case POPOVER_OPEN: {
            return state.map(item => ({ ...item, isOpen: (item.id === action.id) }));
        }

        case POPOVER_CLOSE: {
            return state.map((item) => {
                if (item.id === action.id) {
                    item.isOpen = false;
                }
                return item;
            });
        }

        case POPOVER_TOGGLE: {
            return state.map((item) => {
                if (item.id === action.id) {
                    item.isOpen = !item.isOpen;
                }
                return item;
            });
        }

        case POPOVER_CLOSE_ALL: {
            return state.map( item => ({ ...item, isOpen: false }));
        }

        default: return state;
    }
};
