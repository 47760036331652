import API from '../../helpers/API';

const initialState = {
    authenticated: false,
    email: null,
};

const actionTypes = {
    USER_AUTH: 'USER_AUTH',
    USER_DATA: 'USER_DATA',
    USER_ERROR: 'USER_ERROR',
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_AUTH:
            return {
                ...state,
                authenticated: action.auth,
            };
        case actionTypes.USER_DATA:
            return {
                ...state,
                email: action.user_email,
                level: action.level,
                days_remaining: action.days_remaining,
                date_expires: action.date_expires,
                has_subscription: action.has_subscription || action.has_old_subscription,
            };
        default:
            return state;
    }
};

export const userAuthState = (auth) => ({ type: actionTypes.USER_AUTH, auth: auth });

export const fetchUserData = () => dispatch => {
    API(dispatch).call({
        url: 'auth/permissions',
        onSuccess: (response) => {
            dispatch({
                type: actionTypes.USER_DATA,
                user_email: response.user_email,
                ...response.user_level,
            });
        },
    });
};

export const checkAuth = () => (dispatch, getState) => {
    API(dispatch).call({
        url: 'auth',
        onSuccess: response => {
            if (response.auth !== getState().user.authenticated) {
                dispatch(userAuthState(response.auth));

                if (response.auth) {
                    dispatch(fetchUserData());
                }
            }
        }
    });
};
