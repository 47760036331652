import API from '../../helpers/API';


const initialState = {
    keys: null,
    loading: false,
};

const actionTypes = {
    KEYS_LOADING: 'XT_KEYS_LOADING',
    SET_KEYS: 'XT_SET_KEYS',
    CLEAR_KEYS: 'XT_CLEAR_KEYS',
};

export const extensionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.KEYS_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case actionTypes.SET_KEYS:
            return {
                ...state,
                keys: action.keys,
            };
        case actionTypes.CLEAR_KEYS:
            return {
                ...state,
                keys: null,
            };
        default:
            return state;
    }
};


const keysLoading = (loading = false) => ({ type: actionTypes.KEYS_LOADING, loading });
export const setKeys = keys => ({ type: actionTypes.SET_KEYS, keys });
export const clearKeys = () => ({ type: actionTypes.CLEAR_KEYS });

export const fetchKeys = () => (dispatch) => {
    API(dispatch).call({
        url: 'auth/jwt',
        beforeLoad: () => dispatch(keysLoading(true)),
        onSuccess: (response) => {
            dispatch(keysLoading(false));
            dispatch(setKeys({
                vscode: response.token,
            }));
        },
        onFail: error => { console.log(error); },
    })
};
