const normalizePath = (location) => {
    let page = (typeof location === 'string' ? location : location.pathname);

    page = page.replace(/snippets\/[0-9]+\/?$/gi, 'snippets/snippetId');
    page = page.replace(/embed\/?[0-9]*\/?$/gi, 'embed');

    page = page.replace(/lists\/[0-9]+\/-[0-9]+\/?$/gi, 'lists/listId/newSnippetId');
    page = page.replace(/lists\/[0-9]+\/[0-9]+\/?$/gi, 'lists/listId/snippetId');
    page = page.replace(/lists\/[0-9]+\/?$/gi, 'lists/listId');

    page = page.replace(/collections\/[0-9]+\/[0-9]+\/-[0-9]+\/?$/gi, 'collections/collectionId/listId/newSnippetId');
    page = page.replace(/collections\/[0-9]+\/[0-9]+\/[0-9]+\/?$/gi, 'collections/collectionId/listId/snippetId');
    page = page.replace(/collections\/[0-9]+\/[0-9]+\/?$/gi, 'collections/collectionId/listId');
    page = page.replace(/collections\/[0-9]+\/?$/gi, 'collections/collectionId');

    return page
};


let pageViewTimer = null;

const registerPageView = location => setTimeout(
    () => {
        if (typeof window !== 'undefined' && window.ga) {
            const page = normalizePath(location);

            if (window.location.hostname !== 'localhost') {
                window.ga('send', 'pageview', page);
            } else {
                console.log('Tracking PageView', page);
            }
        }
        pageViewTimer = null;
    },
    250,
);

export const TrackPageView = (location, action) => {
    if (action === "REPLACE" && pageViewTimer !== null) {
        clearTimeout(pageViewTimer);
    }
    pageViewTimer = registerPageView(location);
};


export const TrackEvent = (category, action, label = null, value = null) => {
    if (window.location.hostname !== 'localhost') {
        window.ga('send', 'event', category, action, label, value);
    } else {
        console.log('Tracking Event', category, action, label, value);
    }
};
