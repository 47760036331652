import actionStates from './actionStates';
import API from '../../helpers/API';

const initialState = {
    snippet: null,
    status: actionStates.NOT_LOADED,
};

const actionTypes = {
    SNIPPET_LOADING: 'EMBED.SNIPPET_LOADING',
    SNIPPET_LOADED: 'EMBED.SNIPPET_LOADED',
    SNIPPET_ERROR: 'EMBED.SNIPPET_ERROR',
};


export const embedReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SNIPPET_LOADING:
            return {
                ...state,
                status: actionStates.LOADING,
            };
        case actionTypes.SNIPPET_ERROR:
            return {
                ...state,
                status: actionStates.ERROR,
            };
        case actionTypes.SNIPPET_LOADED:
            return {
                ...state,
                status: actionStates.LOADED,
                snippet: action.snippet,
            };
        default:
            return state;
    }
};


export const getSnippet = (id, callback = null) => dispatch => {
    API(dispatch).call({
        url: `snippets/embed/${id}`,
        method: 'GET',
        beforeLoad: () => dispatch({ type: actionTypes.SNIPPET_LOADING }),
        onSuccess: response => {
            if (response.status === 'ok') {
                dispatch({
                    type: actionTypes.SNIPPET_LOADED,
                    snippet: response.snippet,
                });
            } else {
                dispatch({ type: actionTypes.SNIPPET_ERROR })
            }

            if (typeof callback === 'function') {
                callback(response.snippet);
            }
        },
        onFail: () => {
            dispatch({ type: actionTypes.SNIPPET_ERROR });

            if (typeof callback === 'function') {
                callback();
            }
        },
    });
};

export const pGetSnippet = id => dispatch => new Promise((resolve, reject) => {
    dispatch(getSnippet(id, resolve));
});
