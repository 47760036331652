import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux'
import { Provider as StyletronProvider } from 'styletron-react'
import { BrowserRouter } from 'react-router-dom';
import Loadable from 'react-loadable';

import styletronInstance from './helpers/styletronInstance';
import configureStore from './store/configureStore';
import './css/index.css';
import './css/animations.css';

import App from './App';
import { cmLoad } from './store/actions/cm';


let REDUX_STATE = {};
try {
    REDUX_STATE = JSON.parse(decodeURI(window.__REDUX_STATE__));
} catch (exception) {}

const store = configureStore( REDUX_STATE );

const AppBundle = (
    <ReduxProvider store={store}>
        <StyletronProvider value={styletronInstance}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </StyletronProvider>
    </ReduxProvider>
);

window.onload = () => {
    Loadable.preloadReady().then(() => {
        ReactDOM.hydrate(
            AppBundle,
            document.getElementById('root')
        );

        let callback = () => {};
        if (window.location.pathname.indexOf('/embed/') > -1) {
            callback = initEmbedded;
        }

        store.dispatch(cmLoad(callback));
    });
};

function initEmbedded() {
    if (window.parent !== window) {
        setTimeout(() => {
            const snippetId = window.location.pathname.replace('/embed/', '').replace('/', '');
            const height = document.getElementById('root').offsetHeight;

            window.parent.postMessage(JSON.stringify({
                src: window.location.toString(),
                context: 'iframe.resize',
                height: height, // in pixels
                snippet_id: snippetId,
            }), '*');
        }, 50);
    }
}
